var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
let SalesForceCallback = class SalesForceCallback extends Vue {
    constructor() {
        super(...arguments);
        this.code = '';
    }
    async getTokenSalesForce() {
        // @ts-ignore
        await this.actions.getTokenSalesForce({
            client_id: this.clientIdUrl,
            client_secret: this.clientSecretUrl,
            code: this.code,
        });
    }
    get clientIdUrl() {
        return '3MVG9kBt168mda_8emrcrHB1P70NkwEru9oZsJRamCrxR2T79Tqu0xLl7cBPzm3un.GE6ZA_T8.18hZ9O32tt';
    }
    get clientSecretUrl() {
        return 'CFEADEB99321CAF49BFDC6B7207B01D6F289EE3023C470998DAEC834195F43D2';
    }
    mounted() {
        // @ts-ignore
        this.code = decodeURI(this.$route.query.code);
        this.getTokenSalesForce();
    }
};
SalesForceCallback = __decorate([
    Component(connect(({ state, effects, actions }) => ({
        state: state.users,
        actions: actions.users,
    }), {
        name: 'SalesForceCallback',
    }))
], SalesForceCallback);
export default SalesForceCallback;
